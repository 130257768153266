import { gql } from '@apollo/client';
import {
    GROWTH_AREA_FRAGMENT,
    BASE_USER_FRAGMENT,
    COMPANY_FRAGMENT,
    EXTENDED_USER_FRAGMENT,
    INSIGHT_FRAGMENT,
    PORTRAIT_FRAGMENT,
    QUESTION_FRAGMENT,
    ARTICLE_FRAGMENT,
    LOOKOUT_FRAGMENT,
    PUBLIC_USER_FRAGMENT,
    LEANS_FRAGMENT,
    DAILY_ALIGN_FRAGMENT,
    VIDEO_FRAGMENT,
    TIP_FRAGMENT,
    FEEDBACK_CATEGORY_FRAGMENT,
    ASSIGNATION_FRAGMENT,
} from './fragments';

export const GET_USERS = gql`
    query getUsers {
        users {
            ...BaseUserFragment
        }
    }
    ${BASE_USER_FRAGMENT}
`;

export const CURRENT_USER = gql`
    query currentUser {
        currentUser {
            ...ExtendedUserFragment
            userPortrait {
                ...PortraitFragment
            }
            assignation {
                ...AssignationFragment
            }
        }
    }
    ${PORTRAIT_FRAGMENT}
    ${EXTENDED_USER_FRAGMENT}
    ${ASSIGNATION_FRAGMENT}
`;

export const GET_USER = gql`
    query getUser($id: ID!) {
        getUser(id: $id) {
            ...ExtendedUserFragment
            userPortrait {
                ...PortraitFragment
            }
        }
    }
    ${PORTRAIT_FRAGMENT}
    ${EXTENDED_USER_FRAGMENT}
`;

export const GET_PUBLIC_USER = gql`
    query getUserByPublicUrl($publicUrl: String!) {
        getUserByPublicUrl(publicUrl: $publicUrl) {
            ...PublicUserFragment
            userPortrait {
                ...PortraitFragment
            }
        }
    }
    ${PORTRAIT_FRAGMENT}
    ${PUBLIC_USER_FRAGMENT}
`;

export const GET_ALL_INSIGHTS = gql`
    query getAllInsights {
        insights {
            ...InsightFragment
        }
    }
    ${INSIGHT_FRAGMENT}
`;

export const GET_ALL_GROWTH_AREAS = gql`
    query getAllGrowthAreas {
        growthAreas {
            ...GrowthAreaFragment
        }
    }
    ${GROWTH_AREA_FRAGMENT}
`;

export const GET_ARTICLES = gql`
    query getArticles($limit: Int, $offset: Int) {
        getArticles(limit: $limit, offset: $offset) {
            articles {
                ...ArticleFragment
            }
            hasMore
        }
    }
    ${ARTICLE_FRAGMENT}
`;
export const GET_ARTICLE_BY_URL = gql`
    query getArticleByUrl($url: String) {
        getArticleByUrl(url: $url) {
            ...ArticleFragment
        }
    }
    ${ARTICLE_FRAGMENT}
`;

export const GET_INSIGHT_BY_URL = gql`
    query getInsightByUrl($url: String) {
        getInsightByUrl(url: $url) {
            ...InsightFragment
        }
    }
    ${INSIGHT_FRAGMENT}
`;

export const GET_GROWTH_AREA_BY_URL = gql`
    query getGrowthAreaByUrl($url: String) {
        getGrowthAreaByUrl(url: $url) {
            ...GrowthAreaFragment
        }
    }
    ${GROWTH_AREA_FRAGMENT}
`;

export const GET_ARTICLES_ALL = gql`
    query getArticlesAll {
        getArticlesAll {
            ...ArticleFragment
        }
    }
    ${ARTICLE_FRAGMENT}
`;

export const GET_LOOKOUTS = gql`
    query getLookouts {
        getLookouts {
            ...LookoutFragment
        }
    }
    ${LOOKOUT_FRAGMENT}
`;

export const GET_LOOKOUT_BY_URL = gql`
    query getLookoutByUrl($url: String!, $userId: String) {
        getLookoutByUrl(url: $url, userId: $userId) {
            ...LookoutFragment
            user {
                firstName
                gender
            }
        }
    }
    ${LOOKOUT_FRAGMENT}
`;

export const GET_SPECIFIC_LOOKOUTS = gql`
    query getSpecificLookouts($color1: String!, $color2: String!) {
        getSpecificLookouts(color1: $color1, color2: $color2) {
            ...LookoutFragment
        }
    }
    ${LOOKOUT_FRAGMENT}
`;

export const GET_QUESTIONS = gql`
    query getQuestions {
        questions {
            ...QuestionFragment
        }
    }
    ${QUESTION_FRAGMENT}
`;

export const GET_PORTRAITS = gql`
    query getPortraits {
        portraits {
            ...PortraitFragment
        }
    }
    ${PORTRAIT_FRAGMENT}
`;

export const LOAD_DATA = gql`
    query loadData {
        questions {
            ...QuestionFragment
        }
    }
    ${QUESTION_FRAGMENT}
`;

export const GET_COMPANY = gql`
    query getCompany($name: String!) {
        getCompany(name: $name) {
            id
            name
            users {
                id
                fullName
                firstName
                lastName
                email
                companyName
                title
                isTestFinished
                publicUrl
                personalityData {
                    Yellow
                    Red
                    Blue
                    Green
                    dominant
                    personalityTypes
                }
            }
        }
    }
`;

export const GET_COMPANIES = gql`
    query getCompanies {
        getCompanies {
            ...CompanyFragment
            users {
                ...BaseUserFragment
            }
        }
    }
    ${COMPANY_FRAGMENT}
    ${BASE_USER_FRAGMENT}
`;

export const SEARCH_COMPANIES = gql`
    query searchCompanies($keyword: String!) {
        searchCompanies(keyword: $keyword) {
            name
        }
    }
`;

export const SEARCH_USERS = gql`
    query searchUsers($companyName: String!, $keyword: String) {
        searchUsers(companyName: $companyName, keyword: $keyword) {
            publicUsers {
                id
                fullName
                email
                companyName
                isPublic
                avatarUrl
                publicUrl
                personalityData {
                    Yellow
                    Red
                    Blue
                    Green
                    dominant
                    personalityTypes
                }
            }
            companyUsers {
                id
                fullName
                email
                companyName
                isPublic
                avatarUrl
                publicUrl
                personalityData {
                    Yellow
                    Red
                    Blue
                    Green
                    dominant
                    personalityTypes
                }
            }
        }
    }
`;

export const GET_LEAN_BY_KEYWORD = gql`
    query getLeanByKeyword($keyword: String!) {
        getLeanByKeyword(keyword: $keyword) {
            ...LeansFragment
        }
    }
    ${LEANS_FRAGMENT}
`;

export const GET_LEANS = gql`
    query getLeans {
        getLeans {
            ...LeansFragment
        }
    }
    ${LEANS_FRAGMENT}
`;

export const GET_DAILY_ALIGNS = gql`
    query getDailyAligns {
        getDailyAligns {
            ...DailyAlignFragment
        }
    }
    ${DAILY_ALIGN_FRAGMENT}
`;

export const GET_MIXED_FEED = gql`
    query getMixedFeed($userId: ID!, $limit: Int, $offset: Int) {
        getMixedFeed(userId: $userId, limit: $limit, offset: $offset) {
            data {
                content {
                    ... on Article {
                        ...ArticleFragment
                    }
                    ... on DailyAlign {
                        ...DailyAlignFragment
                    }
                    ... on GrowthArea {
                        ...GrowthAreaFragment
                    }
                    ... on Insight {
                        ...InsightFragment
                    }
                    ... on Tip {
                        ...TipFragment
                    }
                    ... on Video {
                        ...VideoFragment
                    }
                }
                date
            }
            hasMore
        }
    }
    ${ARTICLE_FRAGMENT}
    ${DAILY_ALIGN_FRAGMENT}
    ${GROWTH_AREA_FRAGMENT}
    ${INSIGHT_FRAGMENT}
    ${TIP_FRAGMENT}
    ${VIDEO_FRAGMENT}
`;

export const GET_VIDEOS = gql`
    query getVideos {
        getVideos {
            ...VideoFragment
        }
    }
    ${VIDEO_FRAGMENT}
`;

export const GET_TIPS = gql`
    query getTips {
        getTips {
            ...TipFragment
        }
    }
    ${TIP_FRAGMENT}
`;

export const GET_FEEDBACK_CATEGORIES = gql`
    query getFeedbackCategories {
        getFeedbackCategories {
            ...FeedbackCategoryFragment
        }
    }
    ${FEEDBACK_CATEGORY_FRAGMENT}
`;

export const GET_COMPANY_FEEDBACK_CATEGORIES = gql`
    query getCompanyFeedbackCategories($companyId: ID!) {
        getCompanyFeedbackCategories(companyId: $companyId) {
            categoryId
            title
            subtitle
            question
            companyId
            ratingMark
            user {
                id
                firstName
                lastName
                fullName
                avatarUrl
                gender
                userRatingByCategory
            }
        }
    }
`;
export const GET_FEEDBACK_MONTHLY = gql`
    query getCompanyFeedbackMonthly($companyId: ID!) {
        getCompanyFeedbackMonthly(companyId: $companyId) {
            month
            categories {
                title
                subtitle
                averageRating
                rating
                totalCount
                order
            }
            leaderboard {
                id
                fullName
                firstName
                title
                avatarUrl
                firstCategory
                secondCategory
                thirdCategory
                firstCategoryName
                secondCategoryName
                thirdCategoryName
                streak
            }
        }
    }
`;

export const GET_ARTICLES_CATEGORIES = gql`
    query getArticlesCategories {
        getArticlesCategories
    }
`;

export const GET_ARTICLES_IN_CATEGORY = gql`
    query getArticlesInCategory($category: String, $limit: Int, $offset: Int) {
        getArticlesInCategory(category: $category, limit: $limit, offset: $offset) {
            articles {
                ...ArticleFragment
            }
            hasMore
        }
    }
    ${ARTICLE_FRAGMENT}
`;
